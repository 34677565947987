import React, { useEffect, useRef } from "react";
import { withFormsy } from "formsy-react";
import { Col, Row } from "react-bootstrap";

function TextField(props) {
  const inputRef = useRef();
  useEffect(() => {
    if (props.setValue) {
      if (props.defaultValue && !props.value) {
        props.setValue(props.defaultValue);
      } else if (!props.value) {
        props.setValue("");
      }
    }
  }, [props]);
  let labelText;
  if (props.label) {
    labelText = props.label;
  }
  let label = props.label ? (
    <label style={props.noLabel ? { color: "#fff" } : {}}>{labelText}{props.required && <span className="required-mark">{" *"}</span>}</label>
  ) : undefined;
  const errorMessage = props.errorMessage ? (
    <div style={{ display: "inherit" }} className="invalid-feedback">
      {props.errorMessage}
    </div>
  ) : undefined;
  const field = (
    <>
      <input
        ref={inputRef}
        name={props.name}
        type={props.type}
        required={props.required}
        placeholder={props.placeholder || ""}
        className={`${props.inputClass ? props.inputClass : "form-control"}`}
        onChange={(e) => {
          props.setValue(e.target.value);
          props.onChange && props.onChange(e);
        }}
        value={props.value || ""}
        disabled={props.disabled ? true : false}
      />
    </>
  );
  if (props.inline) {
    return (
      <Row className="align-items-center inline">
        {props.label ? <label className="col-3">{labelText}</label> : undefined}
        <Col>
          {field}
          {props.afterField}
          {errorMessage}
        </Col>
      </Row>
    );
  }
  return (
    <React.Fragment>
      <label>{label}</label>
      {field}
      {errorMessage}
    </React.Fragment>
  );
}

export default withFormsy(TextField);

/*

export class FormsyInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.defaultValue
    };
    return undefined;
  }
  async handleChangeValue(e) {
    const { value } = e.target;
    let val = value;
    if (this.props.onChange) {
      val = await this.props.onChange(e, value);
    }
    return this.setStateAsync({ value: val });
  }

  render() {
    const { onChange, onFocus, ...props } = this.props;

    let labelText;
    if (props.label) {
      labelText = props.label;
    }
    let label = props.label ? (
      <label style={props.noLabel ? { color: "#fff" } : {}}>{labelText}</label>
    ) : undefined;
    const field = (
      <input
        {...rest}
        className="form-control"
        onChange={(e) => this.handleChangeValue(e)}
        value={this.state.value}
      />
    );
    if (props.inline) {
      return (
        <Row className="align-items-center inline">
          {props.label ? <label className="col-3">{labelText}</label> : undefined}
          <Col>
            {field}
            {props.afterField}
          </Col>
        </Row>
      );
    }
    return (
      <React.Fragment>
        {label}
        {field}
      </React.Fragment>
    );
  }
}
*/
