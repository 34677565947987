import React, {Fragment, Component} from "react";
import {withFormsy} from "formsy-react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {Row, Col} from "react-bootstrap";

class PhoneInputField extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      return props.setValue(props.value);
    }
  }

  handleChangeValue = (value) => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    value = value ? value : "";
    return this.props.setValue(value);
  }

  render() {
    const {inline, value, label, className, country = "AU", showError, errorMessage, required} = this.props;
    // An error message is passed only if the component is invalid
    // const errorMessage = this.props.errorMessage;
    const textField = (
      <Fragment>
        <PhoneInput
          flagUrl={"/flags/{XX}.svg"}
          className={`${className ? className : "phone-input"} ${!showError === false ? "is-invalid" : ""}`}
          international
          defaultCountry={"AU"}
          value={value || ""}
          type="text"
          onChange={this.handleChangeValue}
          required={required} />
        { errorMessage ? <div style={{display: "inherit"}} className="invalid-feedback">{errorMessage}</div> : undefined}
      </Fragment>
    );

    if (inline) {
      return (
        <Row className="align-items-center inline">
          {label ? (<label className="col-3">{label}</label>) : undefined}
          <Col>
            {textField}
          </Col>
        </Row>
      );
    }

    return (
      // <div>
      //   <input onChange={(event) => this.props.setValue(event.currentTarget.value)} type="text" value={value || ""} className={`${className ? className : "phone-input"} ${!showError === false ? "is-invalid" : ""}`}/>
      //   { errorMessage ? <div style={{display: "inherit"}} className="invalid-feedback">{errorMessage}</div> : undefined}
      // </div>
      <Fragment>
        {label && <label className="mb-3">{label}</label>}
        {textField}
      </Fragment>
    );
  }
}


export default withFormsy(PhoneInputField);
