import { gql } from "@apollo/client";

export const getPageInfoQuery = gql`
  query getPageInfo($voucherCode: String, $buildingCode: String) {
    siteInfo {
      auEwayClientKey
    }
    classMethods {
      Building {
        findByFormattedAddress(address: "test building") {
          id
          code
          address {
            id
            streetNo
            address
            suburb
            state
            postCode
            country {
              id
              name
            }
          }
        }
      }
      Product {
        getProducts(voucherCode: $voucherCode, buildingCode: $buildingCode) {
          id
          name
          description
          active
          value
          deviceLimit
        }
      }
    }
  }
`;

export function getEwayClientKey({ data }) {
  if (!data || !data.siteInfo) {
    return undefined;
  }
  return data.siteInfo.auEwayClientKey;
}
export function getBuildingAddress({ data }) {
  if (!data || !data.classMethods) {
    return undefined;
  }
  return data.classMethods.Building.findByFormattedAddress[0].address;
}

export function getBuilding({ data }) {
  console.log(data);
  if (!data || !data.classMethods) {
    return undefined;
  }

  return data.classMethods.Building.findByFormattedAddress[0];
}
